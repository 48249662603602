<template>
  <div
    :class="`postMediaSlider ${slideCount > 1 ? 'multiple' : 'single'} ${isOffer ? 'discount' : 'regular'}`"
    :style="`--blurbg:url(${url_encode(currentBlur)})`"
    v-if="slideCount"
  >
    <VueSlickCarousel v-bind="sliderSettings" :ref="`cr-${id}`" @init="initSlider" @beforeChange="beforeChange" @afterChange="afterChange">
      <slot name="default"></slot>
    </VueSlickCarousel>
    <slot name="header"></slot>
    <div class="postMediaSlider-config" v-if="isEdit">
      <div class="postMediaSlider-info" v-if="slideCount > 1 || isEdit">
        <slot name="options"></slot>
        <div class="button count" v-if="isInfo">{{ currentSlide + 1 }}/{{ slideCount }}</div>
        <a class="button delete onEscape" v-if="isEdit" @click="delSlide(currentSlide)"><iconic name="times" /></a>
      </div>
    </div>
    <div class="postMediaSlider-config" v-else-if="slideCount > 1">
      <div class="postMediaSlider-info --onFeed" v-if="slideCount > 1 || isEdit">
        <div class="slot">
          <div class="button count">{{ currentSlide + 1 }}/{{ slideCount }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isEdit", "post", "showInfo", "settings"],
  data: function() {
    return {
      id: this.$global.fastID(),
      isOffer: null,
      currentSlide: 0,
      currentBlur: "",
    };
  },
  methods: {
    initSlider: function() {
      this.$emit("init", { index: this.currentSlide });
      this.setCurrentBackground();
    },
    beforeChange: function(oldSlideIndex, newSlideIndex) {
      this.currentSlide = newSlideIndex;
      this.$emit("beforeChange", { index: this.currentSlide });
      this.setCurrentBackground();
    },
    afterChange: function(currentSlide) {
      this.currentSlide = currentSlide;
      this.$emit("currentSlide", { index: this.currentSlide });
    },
    delSlide: function() {
      this.$emit("delSlide", this.currentSlide);
      this.setCurrentBackground();
    },
    isAvailable: function() {
      if (!this.post) return;
      if (this.post.availableFor === "public") return true;
      if (this.post.subcribeStatus === "active") return true;
      if (this.post.subcribeStatus === "purchased") return true;
    },
    onDiscount: function() {
      if (!this.post) return;
      if (this.post?.discountStatus === "on" && !this.isAvailable()) {
        this.isOffer = true;
      }
      if (this.post.user?.subscriptions?.priceDiscount?.status === "on" && !this.isAvailable()) {
        this.isOffer = true;
      }
    },
    nextSlide: function() {
      this.$refs[`cr-${this.id}`].next();
    },
    setCurrentBackground: function() {
      const mediaFake = this.post && this.post.mediaFake && this.post.mediaFake[this.currentSlide];
      if (mediaFake) {
        this.currentBlur = mediaFake.src || mediaFake.thumb || mediaFake.location;
      }
    },
  },
  mounted: function() {
    this.onDiscount();
    this.setCurrentBackground();
  },
  computed: {
    slideCount: function slideCount() {
      let [slots, numSlots] = [this.$slots?.default, 0];
      [].forEach.call(slots, (slot) => {
        if (slot.tag) numSlots++;
      });
      return numSlots;
    },
    isInfo: function isInfo() {
      return this.showInfo !== false && this.showInfo !== "false";
    },
    sliderSettings: function() {
      const settings = {
        arrows: true,
        dots: false,
        infinite: false,
        initialSlide: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              arrows: false,
            },
          },
        ],
      };

      if (this.settings) {
        Object.assign(settings, this.settings);
      }

      return settings;
    },
  },
};
</script>

<style lang="scss">
.postMediaSlider {
  position: relative;
  width: 100%;
  overflow: hidden;
  .postMediaSlider-info {
    position: absolute;
    top: $mpadding;
    right: $mpadding;
    background-color: #000000a6;
    color: #fff;
    border-radius: 2pc;
    z-index: 3;
    font-size: 90%;
    @include Flex(inherit, center, center);
    .iconic {
      @include Flex(inherit, center, center);
    }
    .button {
      display: inline-block;
      padding: 2px $mradius/2;
      &:not(.count) {
        cursor: pointer;
        &:active {
          @include d-active();
        }
      }
      &:not(:last-child) {
        border-right: solid 1px $alto;
      }
      &.count {
        user-select: none;
      }
    }
  }

  &.multiple ~ * {
    .mark-offer {
      top: 31px;
      right: -41px;
    }
  }

  &.multiple.discount &-info {
    &.--onFeed {
      top: $mpadding/2;
      right: $mpadding/2;
    }
  }

  .slick-track {
    display: flex;
    align-items: center;
    background-color: rgb(36, 36, 36);
    background-image: var(--blurbg);
    background-size: cover;
    background-position: center;
  }

  .slick-dots {
    position: relative;
    bottom: inherit;
  }

  .slick-arrow {
    display: none;
    position: absolute;
    top: 50%;
    width: $mpadding * 4;
    height: 100%;
    z-index: 1;
    &::before {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    &.slick-prev {
      left: 0;
      &::before {
        content: "\f053";
        font-family: Fontawesome;
        background: linear-gradient(270deg, rgba(68, 68, 68, 0) 0%, rgba(68, 68, 68, 0.73) 100%);
      }
    }
    &.slick-next {
      right: 0;
      &::before {
        content: "\f054";
        font-family: Fontawesome;
        background: linear-gradient(270deg, rgba(68, 68, 68, 0.73) 2.08%, rgba(68, 68, 68, 0) 100%);
      }
    }
  }
  @media (min-width: $tablet_width) {
    .slick-arrow {
      display: block;
    }
  }
}
</style>
