<template>
  <div :class="`main-view ${viewClass}`" :id="`view-${viewID}`" :infx="`${inFx}`" :style="`${viewStyle}`" v-bind="open({})">
    <div :class="`main-view-body ${bodyClass}`">
      <slot v-if="hasSlot"></slot>
      <router-view v-else />
    </div>
  </div>
</template>

<script>
export default {
  props: ["meta"],
  data: function() {
    return {
      viewMode: "",
      viewID: "",
      viewClass: "",
      bodyClass: "",
      inFx: "",
      fxTimeTransition: "0.3s",
    };
  },
  methods: {
    elements: function(el) {
      const elmns = {
        body: document.querySelector("body"),
        view: document.querySelector(`#view-${this.viewID}`),
        viewBody: document.querySelector(`#view-${this.viewID} .main-view-body`),
        custom: document.querySelector(el),
      };
      return elmns[el] || elmns["custom"];
    },
    activate: function(elemn, timeout, active, _class, callback) {
      setTimeout(() => {
        active ? this.elements(elemn)?.classList.add(_class) : this.elements(elemn)?.classList.remove(_class);
        if (callback) callback();
      }, +timeout);
    },
    lock: function() {
      this.activate("viewBody", 20, true, "active", () => {});
    },
    unlock: function() {
      const views = document.querySelectorAll(".main-view.active");
      if (views.length < 1) this.activate("body", 0, false, "onview");
    },
    setPropsAttributes: function(meta) {
      this.viewID = this.$global.fastID();
      this.viewMode = meta?.mode ? meta.mode : this.viewMode;
      this.viewClass = meta?.viewClass ? meta.viewClass : this.viewClass;
      this.bodyClass = meta?.bodyClass ? meta.bodyClass : this.bodyClass;
      this.inFx = meta?.inFx ? meta.inFx : this.inFx;
      this.fxTimeTransition = meta?.fxTimeTransition ? meta.fxTimeTransition : this.fxTimeTransition;
    },
    open: function() {
      if (!this.viewMode && this.meta) {
        this.setPropsAttributes({ ...this.meta, mode: "component" });
      }
      this.activate("body", 0, true, "onview");
      this.activate("view", 10, true, "active", () => {
        this.lock();
      });
    },
    close: function(callback) {
      this.activate("viewBody", 0, false, "active", () => {
        this.activate("view", 300, false, "active", () => {
          this.unlock();
          if (callback) callback();
        });
      });
    },
  },
  computed: {
    hasSlot: function() {
      return !!this.$slots.default;
    },
    viewStyle: function() {
      return `--fxtts:${this.fxTimeTransition};min-height:${this.$screen.height}px`;
    },
  },
  destroyed: function() {
    this.unlock();
  },
  beforeRouteLeave: function(to, from, next) {
    if (!this.inFx) return next();
    this.close(() => {
      next();
    });
  },
  beforeRouteEnter: function(to, from, next) {
    next((vm) => {
      vm.setPropsAttributes({ ...to.meta, mode: "router" });
    });
  },
};
</script>

<style lang="scss">
.main-view {
  @include Fixed();
  transition: background 0.3s;
  &.active {
    background: rgba(0, 0, 0, 0.3);
  }
  &-body {
    @include Fixed();
    transition: cubic-bezier(0.39, 0.575, 0.565, 1) var(--fxtts);
  }
  //Animations
  &[infx="rightIn"] &-body {
    transform: translateX(100%);
  }
  &[infx="leftIn"] &-body {
    transform: translateX(-100%);
  }
  &[infx="topIn"] &-body {
    transform: translateY(-100%);
  }
  &[infx="bottomIn"] &-body {
    transform: translateY(100%);
  }
  &[infx="zoomIn"] &-body {
    transform: scale(0.5);
  }
  &[infx="zoomOut"] &-body {
    transform: scale(1.5);
  }
  &[infx="fadeIn"] &-body {
    opacity: 0;
  }
  &.active &-body.active {
    transform: translateX(0);
    transform: translateY(0);
    transform: scale(1);
    opacity: 1;
  }
  //Animations
  &-header {
    position: fixed;
    height: $header_height;
    width: 100%;
    top: 0;
    z-index: 1;
  }
  &-wrap-content {
    position: fixed;
    width: 100%;
    top: 0;
    bottom: 0;
    overflow-y: auto;
  }
  &-content {
    padding: 0 0 $mobile_nav_height 0;
  }
  &-header + &-wrap-content &-content {
    padding: $header_height 0 $mobile_nav_height 0;
    //min-height: 100%;
  }
  @media (min-width: $tablet_width) {
    &-content {
      padding: 0 0 0 0;
    }
  }
}
</style>
