<template>
  <MyView ref="view" :meta="{ viewClass: 'post-visor', bodyClass: `post-visor-body transparent` }" v-swipe="{ down: () => closeVisor() }">
    <Overlay class="loading loading-logo" v-if="loading" />
    <PostMediaSlider :class="`post-visor-slider slider-${id}`" v-if="post" @currentSlide="canPlayLoadedVideo">
      <div v-for="(media, idxmedia) in post.media" :key="`media-${idxmedia}`" :class="`file creator-post-media-wrap`">
        <img alt="Image" v-if="mediaType(media.type) === 'image'" :src="url_encode(media.src)" :index="idxmedia" />
        <video
          v-else-if="mediaType(media.type) === 'video'"
          width="100%"
          controls
          playsinline
          :poster="url_encode(getMediaPoster(media, post))"
          :autoplay="idxmedia + 1 === indexMedia"
          controlslist="nodownload"
          :index="idxmedia"
        >
          <source :src="`${media.src}`" />
        </video>
      </div>
    </PostMediaSlider>
    <div class="post-visor-slider-options">
      <a class="button" onEscape @click="setQuery({ post_view: undefined, mediaIndex: undefined })"><iconic name="times" /></a>
    </div>
  </MyView>
</template>

<script>
import MyView from "../../views/View.vue";
import PostMediaSlider from "../widgets/PostMediaSlider.vue";
import Overlay from "../widgets/Overlay.vue";
export default {
  components: { MyView, PostMediaSlider, Overlay },
  data: function() {
    return {
      post: null,
      loading: true,
      indexMedia: 0,
      id: this.$global.fastID(),
    };
  },
  methods: {
    closeVisor: function() {
      this.setQuery({ post_view: undefined, mediaIndex: undefined });
    },
    getPost: function() {
      this.loading = false;
      this.post = this.$store.state.post;
      if (!this.post) {
        this.loading = true;
        const _host = `${process.env.VUE_APP_API_HOST}posts?&page=1${this.$route.query.post_view ? `&post=${this.$route.query.post_view}` : ""}`;
        const search = this.$api.get(`${_host}`);

        search.then((response) => {
          this.setPostMediaIndex({ post: response.data.posts[0] });
          this.loading = false;
        });

        search.catch((error) => {
          this.loading = false;
          this.ialert.go({ message: error, unique: true, title: "Error" });
        });
      }
    },
    setPostMediaIndex: function({ post }) {
      const { media } = post;
      this.tmpPost = post;
      this.indexMedia = this.$route.query.mediaIndex ? this.$route.query.mediaIndex : 0;

      this.tmpPost.media = media.filter((media, index) => {
        return Number(index) !== Number(this.indexMedia);
      });

      this.mediaIndex = media.find((media, index) => {
        return Number(index) === Number(this.indexMedia);
      });

      if (this.mediaIndex) {
        this.tmpPost.media.unshift(this.mediaIndex);
      }

      this.post = this.tmpPost;
      this.canPlayLoadedVideo({ index: this.indexMedia });
    },
    canPlayLoadedVideo: async function(e) {
      await this.sleep(350);

      this.stopNonIndexVideo();

      const mediaElmn = document.querySelector(`.slider-${this.id} [index="${e.index}"]`);

      if (mediaElmn && mediaElmn.tagName === "VIDEO") {
        mediaElmn.play();
      }
    },
    stopNonIndexVideo: function() {
      const mediaElmns = document.querySelectorAll(`.slider-${this.id} [index]`);
      mediaElmns.forEach((mediaElmn) => {
        if (mediaElmn.tagName === "VIDEO") {
          mediaElmn.pause();
        }
      });
    },
  },
  mounted: function() {
    this.getPost();
  },
};
</script>

<style lang="scss">
.post-visor {
  z-index: 13;
  &-body {
    @include Flex(inherit, center, center);
    @include Glass(#000, 0.7);
  }
  &-slider {
    .slick-track {
      background: rgba(0, 0, 0, 0);
    }
    .slick-slide {
      height: 100%;
      max-height: 100%;
      > div,
      .file-media {
        max-height: 100%;
        height: 100%;
      }
      .creator-post-media-wrap img,
      .creator-post-media-wrap video {
        width: auto;
        margin: auto;
        max-height: 100vh;
      }
    }
    &-options {
      position: fixed;
      top: $mpadding;
      left: $mpadding;
      z-index: 2;
      .button {
        @include Glass(#000, 0.5);
        @include Flex(inherit, center, center);
        width: $mpadding * 3;
        height: $mpadding * 3;
        color: #fff;
        border-radius: 50%;
        cursor: pointer;
        &:active,
        &:hover {
          @include Glass(#fff, 0.5);
        }
      }
    }
  }
}
</style>
